import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'CardEducationMenu',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => ({}),
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            css,
        };
    },
});
