// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-oTbn{display:block}@media screen and (min-width:768px){.-oTbn{border:1px solid #e2e2e2;background-color:#fff;border-radius:8px}}.jgXZu,.ZclzL{display:flex;padding:24px 0;margin:0;grid-gap:8px;gap:8px;list-style:none}@media screen and (min-width:768px){.jgXZu,.ZclzL{flex-flow:wrap;margin-right:16px;margin-left:16px}}.jgXZu{flex-flow:wrap;border:1px solid #e2e2e2;border-right:0;border-left:0;margin-right:8px;margin-left:8px;grid-gap:8px 16px;gap:8px 16px}@media screen and (min-width:768px){.jgXZu{border-top:0;grid-column-gap:20px;-moz-column-gap:20px;column-gap:20px}}@media screen and (min-width:400px){.jgXZu{margin-right:16px;margin-left:16px}}.tljgz{color:#767676;font-size:16px;font-weight:400;line-height:24px}.D2T2l{overflow-x:auto}@media screen and (min-width:768px){.D2T2l{overflow:visible}}@media screen and (min-width:768px){.ZclzL{padding-bottom:16px}}.SHQCw:first-child{padding-left:8px}@media screen and (min-width:400px){.SHQCw:first-child{padding-left:16px}}@media screen and (min-width:768px){.SHQCw:first-child{padding-left:0}}.SHQCw:last-child{padding-right:8px}@media screen and (min-width:400px){.SHQCw:last-child{padding-right:16px}}@media screen and (min-width:768px){.SHQCw:last-child{padding-right:0}}._6HTIF{display:block;padding:6px 16px;background-color:rgba(0,130,255,.08);border-radius:4px;color:#0082ff;font-size:16px;line-height:24px;transition:background-color .3s;white-space:nowrap}._6HTIF:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:rgba(0,130,255,.14)}@media(any-hover:hover){._6HTIF:hover{background-color:rgba(0,130,255,.14)}}._2RINE{display:flex;flex-flow:column;padding:0;margin:0;grid-gap:20px;gap:20px;list-style:none}.-oTbn .Fp2A9{color:#fff;font-size:18px;line-height:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "-oTbn",
	"rubrics": "jgXZu",
	"tags": "ZclzL",
	"rubricLink": "tljgz",
	"tagsContainer": "D2T2l",
	"tag": "SHQCw",
	"tagLink": "_6HTIF",
	"tooltipTags": "_2RINE",
	"tooltipLink": "Fp2A9"
};
module.exports = ___CSS_LOADER_EXPORT___;
